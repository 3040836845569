import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from 'react-modal-video';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";


class DatabaseMigration extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        return(
            <React.Fragment>
                
                {/* Start Pagehelmet  */}
                <PageHelmet pageTitle='SQL Database Migration' />
                {/* End Pagehelmet  */}

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--5"  data-black-overlay="5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="rn-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">MS SQL Database Migration</h2>
                                    <p>Data on the Move</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Page Wrapper */}
                <div className="rn-service-details ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="service-details-inner">
                                    <div className="inner">
                                        {/* Start Single Area */}
                                        <div className="row sercice-details-content pb--80 align-items-center">
                                            <div className="col-lg-6 col-12">
                                                <div className="thumb">
                                                    <img className="w-100" src="/assets/images/service/database-migration.jpg" alt="Database Migration Images"/>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <h4 className="title">High level Plan We Follow To Ensure Minimal Downtime</h4>
                                                    <ul className="liststyle">
                                                        <li><b>Assessment:</b> Assess the current SQL Server environment to identify the size, performance, and complexity of the database. This will help you to determine the effort required for migration and to plan for it.</li>
                                                        <li><b>Planning:</b> Based on the assessment, create a detailed migration plan. This should include timeline, resources needed, potential risks and mitigation plans, and testing strategy. You should also decide on the target SQL Server version.</li>
                                                        <li><b>Backup:</b> Before beginning the migration, ensure you have a complete and recent backup of your SQL Server database. This is critical to restore your system in case something goes wrong during the migration.</li>
                                                        <li><b>Preparation:</b> Prepare the destination server. This includes installing the SQL Server software, setting up the necessary hardware, network, and security configurations, and creating the required databases and schemas.</li>
                                                        <li><b>Migration:</b> Migrate the database from the source to the target server. This can be done using various tools and methods such as the SQL Server Migration Assistant (SSMA), backup/restore, or detach/attach method.</li>
                                                        <li><b>Testing:</b> Once the migration is completed, thoroughly test the new system to ensure that all data has been migrated accurately and that all applications and queries are working as expected.</li>
                                                        <li><b>Switch Over:</b> Once testing is completed and you are satisfied with the new system, switch over from the old system to the new one. This may involve updating connection strings in your applications, updating DNS entries, or reconfiguring network settings.</li>
                                                        <li><b>Monitoring and Optimization:</b> Monitor the new system to ensure it is performing as expected. Optimize the configurations as necessary to achieve the desired performance.</li>
                                                        <li><b>Documentation:</b> Document the new system configuration and any changes made during the migration process.</li>
                                                        <li><b>Decommissioning:</b> Once you are confident that the new system is stable and reliable, decommission the old SQL Server system.</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                        {/* Start Single Area */}
                                        <div className="row sercice-details-content align-items-center">
                                            <div className="col-lg-6 col-12 order-2 order-lg-1">
                                                <div className="details mt_md--30 mt_sm--30">
                                                    <h4 className="title">Why Choose Our SQL Server Migration Services?</h4>
                                                    <ul className="liststyle">
                                                        <li><b>Multi Components:</b> Database Engine, SSIS, SSAS, SSRS.</li>
                                                        <li><b>Expertise:</b> Our team of certified SQL Server professionals possesses in-depth knowledge of SQL Server and its various versions. We stay up-to-date with the latest trends and best practices in migration.</li>
                                                        <li><b>Customized Solutions:</b> We understand that each migration project is unique. We tailor our services to your specific requirements, ensuring a solution that fits your business like a glove.</li>
                                                        <li><b>Risk Mitigation:</b> Migrating data can be risky if not handled correctly. We employ a meticulous planning and execution process to mitigate risks and minimize downtime.</li>
                                                        <li><b>Data Integrity:</b> Your data is your most valuable asset. We take great care to ensure that data integrity is maintained throughout the migration process.</li>
                                                        <li><b>Cost-Efficiency:</b> We work efficiently to save you time and money. Our goal is to complete your migration with minimal disruption to your operations.</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-12 order-1 order-lg-2">
                                                <div className="thumb position-relative">
                                                    <img className="w-100" src="/assets/images/service/database-migration-2.jpg" alt="Database Migration Images"/>
                                                </div>
                                            </div>
                                        </div>
                                        {/* End Single Area */}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Wrapper */}
                
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer />

            </React.Fragment>
        )
    }
}
export default DatabaseMigration;
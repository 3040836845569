import React, { Component } from "react";

class BrandTwo extends Component{
    render(){
        return(
            <React.Fragment>
                <ul className="brand-style-2">
                    <li>
                        <a href="https://www.aixa.ch/" target="_blank">
                        <img src="/assets/images/brand/aixa.png" alt="Aixa Logo"/>
                        </a>
                    </li>
                    <li>
                    <a href="https://www.mooji.io/" target="_blank">
                        <img src="/assets/images/brand/mooji.png" alt="Mooji Logo"/>
                        </a>
                    </li>
                    <li>
                    <a href="https://dascx.com.au/" target="_blank">
                        <img src="/assets/images/brand/dascx.png" alt="DasCX Logo"/>
                        </a>
                    </li>
                    <li>
                    <a href="https://laticrete.com/" target="_blank">
                        <img src="/assets/images/brand/laticrete.png" alt="Laticrete Logo"/>
                        </a>
                    </li>
                    <li>
                    <a href="https://brainbox.consulting/" target="_blank">
                        <img src="/assets/images/brand/brainbox.png" alt="Brainbox Logo"/>
                        </a>
                    </li>
                    <li>
                    <a href="https://legalgpt.info/" target="_blank">
                        <img src="/assets/images/brand/legaldst.jpeg" alt="LegalDST Logo"/>
                        </a>
                    </li>
                    {/* <li>
                        <img src="/assets/images/brand/brand-03.png" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-04.png" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-05.png" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-06.png" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-02.png" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-03.png" alt="Logo Images"/>
                    </li>
                    <li>
                        <img src="/assets/images/brand/brand-04.png" alt="Logo Images"/>
                    </li> */}
                </ul>
            </React.Fragment>
        )
    }
}
export default BrandTwo;
import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import {
  FiCast,
  FiLayers,
  FiUsers,
  FiMonitor,
  FiChevronUp,
  FiBriefcase,
  FiMap,
  FiBook,
} from "react-icons/fi";
import { FaRobot } from "react-icons/fa";
import ScrollToTop from "react-scroll-up";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

const ChatbotServiceList = [
  {
    icon: <FaRobot />,
    title: "Microsoft Bot Framework",
    description:
      "Tools and services for building intelligent bots in multiple programming languages like C#, Node.js, and Python.",
  },
  {
    icon: <FaRobot />,
    title: "Power Virtual Agents",
    description:
      "Microsoft's no-code bot building platform for creating AI-powered chatbots with ease.",
  },
  {
    icon: <FaRobot />,
    title: "RASA",
    description:
      "An open-source framework for building conversational AI chatbots with natural language processing capabilities.",
  },
  {
    icon: <FaRobot />,
    title: "Amazon LEX",
    description:
      "A cloud-based service for building conversational interfaces into applications using voice and text.",
  },
  {
    icon: <FaRobot />,
    title: "DialogFlow",
    description:
      "A natural language understanding platform for building AI-powered chatbots and voice assistants across multiple channels.",
  },
  {
    icon: <FaRobot />,
    title: "ChatGPT",
    description:
      "A large language model trained by OpenAI, based on the GPT-3.5 architecture, for generating human-like responses to text-based inputs.",
  },
];

const ServiceList = [
  {
    icon: <FiCast />,
    title: "Business Stratagy",
    description:
      "The plan and approach for utilizing software development to achieve business goals, which involves identifying target markets, understanding customer needs, analyzing competition, selecting appropriate technologies, and ensuring effective project management.",
  },
  {
    icon: <FiLayers />,
    title: "Website Development",
    description:
      "The process of designing and building websites, typically involving HTML, CSS, and JavaScript, as well as server-side technologies such as PHP or ASP.NET, and content management systems like WordPress or Drupal.",
  },
  {
    icon: <FiUsers />,
    title: "Reporting & Analytics",
    description:
      "The processes of collecting, processing, and analyzing data to generate insights and inform decision-making, as well as creating reports and visualizations to communicate findings to stakeholders.",
  },
  {
    icon: <FiMonitor />,
    title: "Robotic Process Automation (RPA)",
    description:
      "The use of software bots or robots to automate repetitive, rules-based tasks, typically involving user interfaces or APIs, in order to improve efficiency, accuracy, and scalability.",
  },
  {
    icon: <FiCast />,
    title: "Business Automation",
    description:
      "The use of technology and software to streamline and automate business processes, reducing the need for manual intervention, improving efficiency, and increasing productivity.",
  },
  {
    icon: <FiMonitor />,
    title: "OpenAI Development",
    description:
      "The process of designing, training, and testing artificial intelligence models and technologies using open-source tools and frameworks, as well as collaborating with other researchers and organizations to advance the field of AI in a safe and ethical manner.",
  },
];

const databaseMigrationServices = [
    {
        icon: <FiCast />,
        title: "Database Engine"
      },
      {
        icon: <FiLayers />,
        title: "SSIS"
      },
      {
        icon: <FiLayers />,
        title: "SSAS"
      },
      {
        icon: <FiLayers />,
        title: "SSRS"
      },
];

const cscServices = [
  {
      icon: <FiBriefcase />,
      title: "E-Governance"
    },
    {
      icon: <FiLayers />,
      title: "Digital Services"
    },
    {
      icon: <FiMap />,
      title: "Utility Payments"
    },
    {
      icon: <FiBook />,
      title: "Education"
    },
];

class Service extends Component {
  render() {
    return (
      <React.Fragment>
        <PageHelmet pageTitle="Service" />
        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
        />

        {/* Start Breadcrump Area */}
        <Breadcrumb title={"Service"} />
        {/* End Breadcrump Area */}

        {/* Start Service Area */}
        <div className="service-area ptb--120 bg_color--5">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title text-center mb--30">
                  <h2>Chatbot Development</h2>
                  <p>
                    The process of creating and programming a chatbot, typically
                    involving <br />
                    natural language processing, machine learning, and
                    integration with messaging platforms or websites.
                  </p>
                </div>
              </div>
            </div>
            <div className="row service-one-wrapper">
              {ChatbotServiceList.map((val, i) => (
                <div
                  className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12"
                  key={i}
                >
                  <div className="service service__style--2">
                    <div className="icon">{val.icon}</div>
                    <div className="content">
                      <h3 className="title">{val.title}</h3>
                      <p>{val.description}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* End Service Area */}

        {/* Start Service Area */}
        <div className="service-area creative-service-wrapper pt--90 pb--120 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title text-center mb--30">
                  <h2>Database Migration</h2>
                  <p>
                  Database migration is the process of transferring data from one system or environment to another.
                  </p>
                </div>
              </div>
            </div>
            <a href="/service/database-migration">
            <div className="row creative-service">
              {databaseMigrationServices.map((val, i) => (
                <div
                  className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12"
                  key={i}
                >
                  <div className="service service__style--2">
                    <div className="icon">{val.icon}</div>
                    <div className="content">
                      <h3 className="title">{val.title}</h3>
                      <p>{val.description}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            </a>
          </div>
        </div>
        {/* End Service Area */}

        {/* Start Service Area */}
        {/* <div className="service-area ptb--120 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2>Strategy</h2>
                                    <p>There are many variations of passages of Lorem Ipsum available, <br /> but the majority have suffered alteration.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row service-one-wrapper">
                            {ServiceList.map( (val , i) => (
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                    <a className="text-center" href="/service-details">
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div> */}
        {/* End Service Area */}

        {/* Start Service Area */}
        {/* <div className="service-area creative-service-wrapper pt--90 pb--120 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center mb--30">
                                    <h2>Creative Agency</h2>
                                    <p>There are many variations of passages of Lorem Ipsum available, <br /> but the majority have suffered alteration.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row creative-service">
                            {ServiceList.map( (val , i) => (
                                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                                    <a href="/service-details">
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div> */}
        {/* End Service Area */}

        {/* Start Service Area */}
        <div className="service-area creative-service-wrapper pt--90 pb--120 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title text-center mb--30">
                  <h2>Software Development</h2>
                  <p>
                    The process of designing, creating, testing, and maintaining
                    software applications or systems, <br /> typically involving
                    programming languages, frameworks, and tools.
                  </p>
                </div>
              </div>
            </div>
            <div className="row creative-service">
              {ServiceList.map((val, i) => (
                <div
                  className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12"
                  key={i}
                >
                  <div className="service service__style--2">
                    <div className="icon">{val.icon}</div>
                    <div className="content">
                      <h3 className="title">{val.title}</h3>
                      <p>{val.description}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* End Service Area */}

        <div className="service-area creative-service-wrapper pt--90 pb--120 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title text-center mb--30">
                  <h2>Dewiride CSC</h2>
                  <p>
                  Explore Dewiride CSC, your local Common Service Center providing digital services, e-governance, financial inclusion, and empowerment in rural India.
                  </p>
                </div>
              </div>
            </div>
            <a href="https://csc.dewiride.com" target="_blank">
            <div className="row creative-service">
              {cscServices.map((val, i) => (
                <div
                  className="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12"
                  key={i}
                >
                  <div className="service service__style--2">
                    <div className="icon">{val.icon}</div>
                    <div className="content">
                      <h3 className="title">{val.title}</h3>
                      <p>{val.description}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            </a>
          </div>
        </div>

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </React.Fragment>
    );
  }
}
export default Service;

import React, { Component } from "react";

const ServiceList = [
    {
        icon: '01',
        title: 'Chatbot Development',
        description: 'Microsoft Bot Framework, RASA, Power Virtual Agents, Amazon LEX, DialogFlow, Bot Framework Composer, ChatGPT'
    },
    {
        icon: '02',
        title: 'Website Development',
        description: 'React.js, WordPress, ASP.NET, SaaS, HTML, CSS, JavaScript'
    },
    {
        icon: '03',
        title: 'Reporting & Analytics',
        description: 'Power BI, Microsoft Excel'
    },
    {
        icon: '04',
        title: 'Database Migration',
        description: 'Microsoft SQL Server'
    }
]

class ServiceOne extends Component{
    render(){
        return(
            <React.Fragment>
                <div className="row">
                    {ServiceList.map( (val , i) => (
                        <div className="col-lg-3 col-md-6 col-sm-6 col-12" key={i}>
                            <div className="service service__style--1">
                                <div className="icon">
                                    <img src={`/assets/images/icons/icon-${val.icon}.png`} alt="Digital Agency"/>
                                </div>
                                <div className="content">
                                    <h4 className="title">{val.title}</h4>
                                    <p>{val.description}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceOne;
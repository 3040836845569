import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from 'react-modal-video';
import { FiClock , FiUser , FiMessageCircle , FiHeart } from "react-icons/fi";
import { Link } from 'react-router-dom';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

class BlogDetails extends Component{
    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }
    openModal () {
        this.setState({isOpen: true})
    }
    render(){
        return(
            <React.Fragment>
                <PageHelmet pageTitle='Privacy Policy' />
                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
                
                {/* Start Breadcrump Area */}
                <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--7" data-black-overlay="7">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="blog-single-page-title text-center pt--100">
                                    <h2 className="title theme-gradient">Privacy Policy</h2>
                                    <ul className="blog-meta d-flex justify-content-center align-items-center">
                                        <li><FiClock />Last Updated: May 4, 2023</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Breadcrump Area */}

                {/* Start Privacy Policy */}
                <div className="rn-blog-details pt--110 pb--70 bg_color--1">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner-wrapper">
                                    <div className="inner">
                                        <p><b>Dewiride Technologies Private Limited</b> (referred to as "we," "us," or "our") is committed to protecting the privacy of its users. This privacy policy explains how we collect, use, and protect personal information collected through our website, events, and other services.</p>
                                        
                                        <h4 className="title">Information We Collect</h4>

                                        <p className="mt--40">We may collect personal information from users in various ways, including:
                                        <ol className="list-style">
                                            <li>Information provided voluntarily by the user, such as name, email address, company name, country, industry, job title, and any other information provided in registration forms.</li>
                                            <li>Information collected automatically when users visit our website, such as IP address, browser type, device type, and other information collected through cookies or other tracking technologies.</li>
                                        </ol>
                                        </p>
                                        
                                        <h4 className="title">How We Use Information</h4>

                                        <p className="mt--40">We may use the personal information collected for the following purposes:
                                        <ol className="list-style">
                                            <li>To provide, operate, and maintain our website.</li>
                                            <li>To improve, personalize, and expand our website.</li>
                                            <li>To understand and analyze how users use our website.</li>
                                            <li>To develop new products, services, features, and functionality.</li>
                                            <li>To communicate with users about our products and services, including sending promotional emails about our products and services and upcoming events.</li>
                                            <li>To send emails to users.</li>
                                            <li>To find and prevent fraud.</li>
                                            <li>To provide our services, such as building chatbots, websites, RPA, reporting, etc.</li>
                                            <li>To comply with applicable laws, regulations, and legal processes.</li>
                                        </ol>
                                        </p>
                                        
                                        <h4 className="title">Disclosure of information</h4>
                                        <p className="mt--40">We may disclose personal information to third-party service providers who perform services on our behalf, such as hosting services, payment processing, or analytics. We may also disclose personal information when required by law or in response to legal process.</p>
                                        <p>We do not sell or rent personal information to third parties.</p>

                                        <h4 className="title">Your choices</h4>
                                        <p className="mt--40">Users can choose not to provide personal information to us, but this may limit our ability to provide certain services.</p>
                                        <p>Users can also choose to opt-out of receiving promotional emails from us by following the instructions included in the email or by contacting us directly.</p>

                                        <h4 className="title">Security</h4>
                                        <p className="mt--40">We take reasonable measures to protect the personal information we collect from unauthorized access, disclosure, alteration, or destruction. However, no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.</p>

                                        <h4 className="title">Changes to this policy</h4>
                                        <p className="mt--40">We may update this privacy policy from time to time. Any changes will be effective when we post the updated privacy policy on our website.</p>

                                        <h4 className="title">Contact us</h4>
                                        <p className="mt--40">If you have any questions or concerns about our privacy policy, please contact us at <a href="mailto:contact@dewiride.com">contact@dewiride.com</a>
                                            </p>
                                            <p>This privacy policy is governed by and construed in accordance with the laws of India. Any dispute arising under this policy shall be resolved exclusively by the courts located in India.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Privacy Policy */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                
                <Footer /> 

            </React.Fragment>
        )
    }
}
export default BlogDetails;
import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from "react-modal-video";
import { FiClock, FiUser, FiMessageCircle, FiHeart } from "react-icons/fi";
import { Link } from "react-router-dom";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

class TermsConditions extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    return (
      <React.Fragment>
        <PageHelmet pageTitle="Terms & Conditions" />
        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
        />

        {/* Start Breadcrump Area */}
        <div
          className="rn-page-title-area pt--120 pb--190 bg_image bg_image--7"
          data-black-overlay="7"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="blog-single-page-title text-center pt--100">
                  <h2 className="title theme-gradient">Terms & Conditions</h2>
                  <ul className="blog-meta d-flex justify-content-center align-items-center">
                    <li>
                      <FiClock />
                      Last Updated: May 4, 2023
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}

        {/* Start Privacy Policy */}
        <div className="rn-blog-details pt--110 pb--70 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner-wrapper">
                  <div className="inner">
                    <p>
                      <b>Terms and Conditions</b> (referred to as "Terms")
                    </p>

                    <h4 className="title">1. Introduction</h4>

                    <p className="mt--40">
                      Welcome to the website of Dewiride Technologies Private
                      Limited (referred to as "we," "us," or "our"). By
                      accessing and using our website, you agree to comply with
                      and be bound by these Terms and any other policies or
                      guidelines posted on our website. If you do not agree with
                      these Terms, please do not use our website.
                    </p>

                    <h4 className="title">2. Use of the Website</h4>

                    <p className="mt--40">
                      You must be at least 18 years old to use our website. By
                      using our website, you represent and warrant that you are
                      at least 18 years old and have the legal capacity to enter
                      into these Terms.
                    </p>

                    <p>
                      You agree to use our website for lawful purposes only and
                      not to engage in any activity that violates any applicable
                      laws, regulations, or third-party rights.
                    </p>

                    <p>
                      We may, at our sole discretion, terminate or suspend your
                      access to our website if you violate any of these Terms or
                      for any other reason without prior notice.
                    </p>

                    <h4 className="title">3. Intellectual Property</h4>

                    <p className="mt--40">
                      The content, materials, and features available on our
                      website, including but not limited to text, graphics,
                      logos, images, videos, and software, are owned by or
                      licensed to us and are protected by intellectual property
                      laws. You may not use, copy, modify, distribute, or
                      reproduce any content from our website without our prior
                      written consent.
                    </p>

                    <h4 className="title">4. Privacy</h4>

                    <p className="mt--40">
                      Our Privacy Policy governs the collection, use, and
                      protection of personal information provided by you. By
                      using our website, you consent to the practices described
                      in our Privacy Policy.
                    </p>

                    <h4 className="title">5. Limitation of Liability</h4>

                    <p className="mt--40">
                      In no event shall Dewiride Technologies Private Limited or
                      its affiliates, partners, directors, employees, or agents
                      be liable for any indirect, incidental, special,
                      consequential, or punitive damages, including but not
                      limited to loss of profits, data, or goodwill, arising
                      from your use of our website or any content or services
                      provided through our website.
                    </p>

                    <p>
                      You agree to indemnify and hold harmless Dewiride
                      Technologies Private Limited and its affiliates, partners,
                      directors, employees, or agents from any claims,
                      liabilities, damages, or expenses (including reasonable
                      attorneys' fees) arising out of or related to your use of
                      our website, violation of these Terms, or infringement of
                      any intellectual property or other rights of any third
                      party.
                    </p>

                    <h4 className="title">6. Governing Law and Jurisdiction</h4>

                    <p className="mt--40">
                      These Terms shall be governed by and construed in
                      accordance with the laws of India. Any dispute arising
                      under these Terms shall be resolved exclusively by the
                      courts located in India.
                    </p>

                    <h4 className="title">7. Modifications</h4>

                    <p className="mt--40">
                      We reserve the right to modify or update these Terms at
                      any time without prior notice. Your continued use of our
                      website after any changes to these Terms constitutes your
                      acceptance of the revised Terms.
                    </p>

                    <h4 className="title">8. Non-payment of Invoices</h4>
                    <p className="mt--40">
                      In the event that a client/customer fails to make payment
                      for invoices within 15 days of the due date, despite
                      receiving multiple reminders from us, we reserve the right
                      to take the following actions:
                    </p>
                    <ol className="list-style">
                      <li>
                        Make the work done by us publicly available: We may
                        showcase and display the completed work, including but
                        not limited to code, designs, or other deliverables,
                        publicly on our website or other platforms to
                        demonstrate the quality of our services.
                      </li>
                      <li>
                        Sale on our Repository website: If the outstanding
                        invoice remains unpaid for an extended period, we may
                        offer to sell the completed work on our Repository
                        website to recover the outstanding amount.
                      </li>
                    </ol>
                    <p>
                      Please note that we will make reasonable efforts to
                      communicate with the client/customer regarding payment
                      reminders before taking any such actions, and our primary
                      goal is to find an amicable resolution to any payment
                      issues. However, if the client/customer remains
                      non-responsive or fails to fulfill their payment
                      obligations, we may take the aforementioned actions as
                      permitted by these Terms.
                    </p>

                    <h4 className="title">9. Contact Us</h4>

                    <p className="mt--40">
                      If you have any questions or concerns about these Terms,
                      please contact us at{" "}
                      <a href="mailto:contact@dewiride.com">
                        contact@dewiride.com
                      </a>
                      .
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Privacy Policy */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </React.Fragment>
    );
  }
}
export default TermsConditions;

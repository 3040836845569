import React, { Component } from "react";
import {Helmet} from 'react-helmet'

class PageHelmet extends Component{
    render(){
        return(
            <React.Fragment>
                <Helmet>
                    <title>Dewiride Technologies | Deliver with Pride | Building Chatbots | SQL DB Migration | Azure OpenAI | RPA | ChatGPT</title>
                    <meta name="description" content="Drive growth and success with our chatbot development, website development, reporting & analytics and SQL DB Migration services and ChatGPT, Azure Bot & More." />
                </Helmet>
            </React.Fragment>
        )
    }
}


export default PageHelmet;

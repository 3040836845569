import React, { Component } from "react";
import { FiCast , FiLayers , FiUsers , FiMonitor } from "react-icons/fi";
import { FaRobot } from "react-icons/fa";

const ServiceList = [
    {
        icon: <FaRobot />,
        title: 'Microsoft Bot Framework',
        description: 'Tools and services for building intelligent bots in multiple programming languages like C#, Node.js, and Python.'
            },
            {
                icon: <FaRobot />,
                title: 'Power Virtual Agents',
                description: "Microsoft's no-code bot building platform for creating AI-powered chatbots with ease."
                    },
                    {
                        icon: <FaRobot />,
                        title: 'RASA',
                        description: "An open-source framework for building conversational AI chatbots with natural language processing capabilities."
                            },
                            {
                                icon: <FaRobot />,
                                title: 'Amazon LEX',
                                description: "A cloud-based service for building conversational interfaces into applications using voice and text."
                                    },
                                    {
                                        icon: <FaRobot />,
                                        title: 'DialogFlow',
                                        description: "A natural language understanding platform for building AI-powered chatbots and voice assistants across multiple channels."
                                            },
                                            {
                                                icon: <FaRobot />,
                                                title: 'ChatGPT',
                                                description: "A large language model trained by OpenAI, based on the GPT-3.5 architecture, for generating human-like responses to text-based inputs."
                                                    }
]

class ServiceTwo extends Component{
    render(){
        let title = 'Services',
        description = 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration.';
        return(
            <React.Fragment>
                <div className="row">
                    <div className="col-lg-4 col-12">
                        <div className="section-title mt--30 mt_md--5 mt_mobile--5 mb_mobile--10">
                            <h2 className="title">{title}</h2>
                            {/* <p>{description}</p> */}
                            <div className="service-btn">
                                <a className="btn-transparent rn-btn-dark" href="/service"><span className="text">Request Custom Service</span></a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8 col-12 mt_md--50">
                        <div className="row service-one-wrapper">
                            {ServiceList.map( (val , i) => (
                                <div className="col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
                                    <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceTwo;

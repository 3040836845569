import React from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp, FiCheck, FiX } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

const PricingTable = () => {
  return (
    <>
      <PageHelmet pageTitle="Pricing" />

      {/* Start Header Area  */}
      <Header
        headertransparent="header--transparent"
        colorblack="color--black"
        logoname="logo.png"
      />
      {/* End Header Area  */}

      {/* Start Breadcrump Area */}
      <Breadcrumb title={"Pricing"} />
      {/* End Breadcrump Area */}

      {/* Start Page Wrapper  */}
      <main className="page-wrapper">
        {/* Start Pricing Tbale Area  */}
        <div className="rn-pricing-table-area ptb--120 bg_color--5">
          <div className="section-title text-center mb--30">
            <h2>Microsoft Azure Chatbot Development</h2>
          </div>
          <div className="container">
            <div className="row">
              {/* Start PRicing Table Area  */}
              <div className="col-lg-4 col-md-6 col-12">
                <div className="rn-pricing">
                  <div className="pricing-table-inner">
                    <div className="pricing-header">
                      <h4 className="title">FAQ Bot</h4>
                      <div className="pricing">
                        <span className="price">200</span>
                        <span className="subtitle">USD</span>
                      </div>
                    </div>
                    <div className="pricing-body">
                      <ul className="list-style--1">
                        <li>
                          <FiCheck /> Custom Question Answering
                        </li>
                        <li>
                          <FiCheck /> Training FAQs
                        </li>
                        <li>
                          <FiCheck /> ChatGPT enabled
                        </li>
                        <li>
                          <FiCheck /> Deployment
                        </li>
                        <li>
                          <FiCheck /> Channel Integration
                        </li>
                        <li>
                          <FiX /> Future maintanence
                        </li>
                      </ul>
                    </div>
                    <div className="pricing-footer">
                      <a className="rn-btn" href="contact">
                        Build now
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {/* End PRicing Table Area  */}

              {/* Start PRicing Table Area  */}
              <div className="col-lg-4 col-md-6 col-12">
                <div className="rn-pricing active">
                  <div className="pricing-table-inner">
                    <div className="pricing-header">
                      <h4 className="title">Conversational Intent Based</h4>
                      <div className="pricing">
                        <span className="price">80</span>
                        <span className="subtitle">USD Per Hour</span>
                      </div>
                    </div>
                    <div className="pricing-body">
                      <ul className="list-style--1">
                        <li>
                          <FiCheck /> CLU Integration
                        </li>
                        <li>
                          <FiCheck /> Custom Question Answering
                        </li>
                        <li>
                          <FiCheck /> ChatGPT on your data using Azure OpenAI
                        </li>
                        <li>
                          <FiCheck /> Dialogs
                        </li>
                        <li>
                          <FiCheck /> Deployment
                        </li>
                        <li>
                          <FiCheck /> Channel Integration
                        </li>
                      </ul>
                    </div>
                    <div className="pricing-footer">
                      <a className="rn-btn" href="contact">
                        Build now
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {/* End PRicing Table Area  */}

              {/* Start PRicing Table Area  */}
              <div className="col-lg-4 col-md-6 col-12">
                <div className="rn-pricing">
                  <div className="pricing-table-inner">
                    <div className="pricing-header">
                      <h4 className="title">Custom Solution</h4>
                      <div className="pricing">
                        <span className="price">Ask</span>
                        <span className="subtitle">USD Per Hour</span>
                      </div>
                    </div>
                    <div className="pricing-body">
                      <ul className="list-style--1">
                        <li>
                          <FiCheck /> All on the other 2 plans
                        </li>
                        <li>
                          <FiCheck /> DB Integrations
                        </li>
                        <li>
                          <FiCheck /> Custom API Integration
                        </li>
                      </ul>
                    </div>
                    <div className="pricing-footer">
                      <a className="rn-btn" href="contact">
                        Build now
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {/* End PRicing Table Area  */}
            </div>
          </div>
        </div>
        {/* End Pricing Tbale Area  */}
      </main>
      {/* End Page Wrapper  */}

      {/* Start Page Wrapper  */}
      <main className="page-wrapper">
        {/* Start Pricing Tbale Area  */}
        <div className="rn-pricing-table-area ptb--120 bg_color--5">
          <div className="section-title text-center mb--30">
            <h2>SQL DB Migration</h2>
          </div>
          <div className="container">
            <div className="row">
              {/* Start PRicing Table Area  */}
              <div className="col-lg-4 col-md-6 col-12 mx-auto">
                <div className="rn-pricing active">
                  <div className="pricing-table-inner">
                    <div className="pricing-header">
                      {/* <h4 className="title"></h4> */}
                      <div className="pricing">
                        <span className="price">100</span>
                        <span className="subtitle">USD Per Hour</span>
                      </div>
                    </div>
                    <div className="pricing-body">
                      <ul className="list-style--1">
                        <li>
                          <FiCheck /> Database Engine
                        </li>
                        <li>
                          <FiCheck /> SSIS
                        </li>
                        <li>
                          <FiCheck /> SSAS
                        </li>
                        <li>
                          <FiCheck /> SSRS
                        </li>
                      </ul>
                    </div>
                    <div className="pricing-footer">
                      <a className="rn-btn" href="contact">
                        Migrate now
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {/* End PRicing Table Area  */}
            </div>
          </div>
        </div>
        {/* End Pricing Tbale Area  */}
      </main>
      {/* End Page Wrapper  */}

      {/* Start Page Wrapper  */}
      <main className="page-wrapper">
        {/* Start Pricing Tbale Area  */}
        <div className="rn-pricing-table-area ptb--120 bg_color--5">
          <div className="section-title text-center mb--30">
            <h2>For Startups Developing Applications</h2>
            <p>
              Pricing begins with a 40-hour package, decreasing progressively
              with extended durations.{" "}
              <br />
                Eligibility is limited to startups that commenced operations
                within the past year.
              <br />
            </p>
          </div>
          <div className="container">
            <div className="row">
              {/* Start PRicing Table Area  */}
              <div className="col-lg-4 col-md-6 col-12">
                <div className="rn-pricing">
                  <div className="pricing-table-inner">
                    <div className="pricing-header">
                      <h4 className="title">40 hours duration</h4>
                      <div className="pricing">
                        <span className="price">80</span>
                        <span className="subtitle">USD Per Hour</span>
                      </div>
                    </div>
                    <div className="pricing-body">
                      <ul className="list-style--1">
                        <li>
                          <FiCheck /> Chatbot development
                        </li>
                        <li>
                          <FiCheck /> Indexing Data
                        </li>
                        <li>
                          <FiCheck /> API Development
                        </li>
                        <li>
                          <FiCheck /> ChatGPT Integrations
                        </li>
                      </ul>
                    </div>
                    <div className="pricing-footer">
                      <a className="rn-btn" href="contact">
                        Build now
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {/* End PRicing Table Area  */}

              {/* Start PRicing Table Area  */}
              <div className="col-lg-4 col-md-6 col-12">
                <div className="rn-pricing active">
                  <div className="pricing-table-inner">
                    <div className="pricing-header">
                      <h4 className="title">40 to 100 hours duration</h4>
                      <div className="pricing">
                        <span className="price">70</span>
                        <span className="subtitle">USD Per Hour</span>
                      </div>
                    </div>
                    <div className="pricing-body">
                      <ul className="list-style--1">
                        <li>
                          <FiCheck /> Advanced Chatbot development
                        </li>
                        <li>
                          <FiCheck /> Conversational Language Understanding
                        </li>
                        <li>
                          <FiCheck /> Custom Question Answering
                        </li>
                        <li>
                          <FiCheck /> ChatGPT on your data using Azure OpenAI
                        </li>
                        <li>
                          <FiCheck /> Dialogs
                        </li>
                        <li>
                          <FiCheck /> Deployment
                        </li>
                        <li>
                          <FiCheck /> Channel Integration
                        </li>
                      </ul>
                    </div>
                    <div className="pricing-footer">
                      <a className="rn-btn" href="contact">
                        Build now
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {/* End PRicing Table Area  */}

              {/* Start PRicing Table Area  */}
              <div className="col-lg-4 col-md-6 col-12">
                <div className="rn-pricing">
                  <div className="pricing-table-inner">
                    <div className="pricing-header">
                      <h4 className="title">More than 100 hours duration</h4>
                      <div className="pricing">
                        <span className="price">60</span>
                        <span className="subtitle">USD Per Hour</span>
                      </div>
                    </div>
                    <div className="pricing-body">
                      <ul className="list-style--1">
                        <li>
                          <FiCheck /> All on the other 2 plans
                        </li>
                        <li>
                          <FiCheck /> SaaS Solution
                        </li>
                        <li>
                          <FiCheck /> Web Application (.NET Only)
                        </li>
                        <li>
                          <FiCheck /> MVP
                        </li>
                      </ul>
                    </div>
                    <div className="pricing-footer">
                      <a className="rn-btn" href="contact">
                        Build now
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {/* End PRicing Table Area  */}
            </div>
          </div>
        </div>
        {/* End Pricing Tbale Area  */}
      </main>
      {/* End Page Wrapper  */}

      {/* Start Page Wrapper  */}
      <main className="page-wrapper">
        {/* Start Pricing Tbale Area  */}
        <div className="rn-pricing-table-area ptb--120 bg_color--5">
          <div className="section-title text-center mb--30">
            <h2>Training Services</h2>
          </div>
          <div className="container">
            <div className="row">
              {/* Start PRicing Table Area  */}
              <div className="col-lg-4 col-md-6 col-12">
                <div className="rn-pricing">
                  <div className="pricing-table-inner">
                    <div className="pricing-header">
                      <h4 className="title">Beginner</h4>
                      <div className="pricing">
                        <span className="price">40</span>
                        <span className="subtitle">USD per hour</span>
                      </div>
                    </div>
                    <div className="pricing-body">
                      <ul className="list-style--1">
                        <li>
                          <FiCheck /> Basic Chatbot Development - Microsoft Bot
                          Framework
                        </li>
                        <li>
                          <FiCheck /> Power Automate
                        </li>
                      </ul>
                    </div>
                    <div className="pricing-footer">
                      <a className="rn-btn" href="contact">
                        Get training
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {/* End PRicing Table Area  */}

              {/* Start PRicing Table Area  */}
              <div className="col-lg-4 col-md-6 col-12">
                <div className="rn-pricing active">
                  <div className="pricing-table-inner">
                    <div className="pricing-header">
                      <h4 className="title">Intermediate</h4>
                      <div className="pricing">
                        <span className="price">50</span>
                        <span className="subtitle">USD Per Hour</span>
                      </div>
                    </div>
                    <div className="pricing-body">
                      <ul className="list-style--1">
                        <li>
                          <FiCheck /> Chatbot Development - Microsoft Bot
                          Framework
                        </li>
                        <li>
                          <FiCheck /> Power Automate
                        </li>
                        <li>
                          <FiCheck /> Power Virtual Agents
                        </li>
                        <li>
                          <FiCheck /> Azure OpenAI Integration - ChatGPT
                        </li>
                      </ul>
                    </div>
                    <div className="pricing-footer">
                      <a className="rn-btn" href="contact">
                        Get training
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {/* End PRicing Table Area  */}

              {/* Start PRicing Table Area  */}
              <div className="col-lg-4 col-md-6 col-12">
                <div className="rn-pricing">
                  <div className="pricing-table-inner">
                    <div className="pricing-header">
                      <h4 className="title">Custom</h4>
                      <div className="pricing">
                        <span className="price">Ask</span>
                        <span className="subtitle">USD Per Hour</span>
                      </div>
                    </div>
                    <div className="pricing-body">
                      <ul className="list-style--1">
                        <li>
                          <FiCheck /> Training on your usecase
                        </li>
                      </ul>
                    </div>
                    <div className="pricing-footer">
                      <a className="rn-btn" href="contact">
                        Get training
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {/* End PRicing Table Area  */}
            </div>
          </div>
        </div>
        {/* End Pricing Tbale Area  */}
      </main>
      {/* End Page Wrapper  */}

      {/* Start Page Wrapper  */}
      <main className="page-wrapper">
        {/* Start Pricing Tbale Area  */}
        <div className="rn-pricing-table-area ptb--120 bg_color--5">
          <div className="section-title text-center mb--30">
            <h2>WhatsApp Group Membership</h2>
            <p>
              WhatsApp group for having Q&A sessions and getting latest updates.{" "}
              <br />
              We are making this free for all.
            </p>
          </div>
          <div className="container">
            <div className="row">
              {/* Start PRicing Table Area  */}
              
              {/* End PRicing Table Area  */}

              {/* Start PRicing Table Area  */}
              
              {/* End PRicing Table Area  */}

              {/* Start PRicing Table Area  */}
              <div className="col-lg-4 col-md-6 col-12">
                <div className="rn-pricing">
                  <div className="pricing-table-inner">
                    <div className="pricing-header">
                      <h4 className="title">WhatsApp Group Invitation</h4>
                    </div>
                    <div className="pricing-footer">
                      <a
                        className="rn-btn"
                        href="https://chat.whatsapp.com/Bcl7mWDEZB09j2cJt7UZ5s"
                      >
                        Request to Join
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {/* End PRicing Table Area  */}
            </div>
          </div>
        </div>

        {/* End Pricing Tbale Area  */}
      </main>
      {/* End Page Wrapper  */}

      {/* Start Back To Top */}
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      {/* End Back To Top */}

      {/* Start Footer Area  */}
      <Footer />
      {/* End Footer Area  */}
    </>
  );
};
export default PricingTable;
